import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faEdit, faSave, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { LoadingComponent } from 'src/app/shared/components/ui-components/loading/loading.component';
import { UserCardComponent } from 'src/app/shared/components/ui-components/user-card/user-card.component';
import { Commission } from 'src/app/shared/models';
import { AggregatedExecutiveRuleCommissions } from 'src/app/shared/models/classes/executive';
import { ReversePipe } from 'src/app/shared/pipe/reverse.pipe';
import { PayoutCustomService } from 'src/app/shared/services/payout/payout-custom.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-commissions-custom-payout-edit',
  standalone: true,
  imports: [
    CurrencyPipe,
    FaIconComponent,
    FormsModule,
    LoadingComponent,
    ReversePipe,
    UserCardComponent,
  ],
  templateUrl: './commissions-custom-payout-edit.component.html',
  styleUrl: './commissions-custom-payout-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommissionsCustomPayoutEditComponent implements OnInit, OnDestroy {
  faEdit = faEdit;
  faSave = faSave;
  faTrash = faTrash;

  @Input() commission?: Commission;

  aggregatedExecutiveRuleCommissions?: AggregatedExecutiveRuleCommissions;
  payoutsByUser: { [key: string]: number } = {};

  totalCustomPayouts?: number;

  loading = false;
  loadingSave = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private payoutCustomService: PayoutCustomService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    console.log('COMMIS', this.commission);
    if (this.commission) {
      if (this.commission.payout?.byUser) {
        this.payoutsByUser = structuredClone(this.commission.payout.byUser);
        this.payoutsByUserChanged();
      }
      if (this.commission.aggregatedExecutiveRuleCommissions) {
        this.aggregatedExecutiveRuleCommissions =
          this.commission.aggregatedExecutiveRuleCommissions;

        for (const userId of this.commission.aggregatedExecutiveRuleCommissions
          .executiveLadder) {
          if (
            this.payoutsByUser[userId] === null ||
            this.payoutsByUser[userId] === undefined
          ) {
            this.payoutsByUser[userId] = 0;
          }
        }
      }
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    delete this.aggregatedExecutiveRuleCommissions;
    delete this.totalCustomPayouts;

    this.payoutsByUser = {};
  }

  createCustomPayouts() {
    if (!this.commission) {
      throw new Error('Commission is required');
    }
    this.loading = true;
    this.payoutCustomService
      .convertToCustomPayout(this.commission)
      .then(data => {
        console.log('createCustomPayouts res', data);

        if (data?.commission) {
          if (this.commission) {
            if (!this.commission.custom) {
              this.commission.custom = {};
            }
            this.commission.custom.isCustom = true;
          }

          if (data.commission.payout?.byUser) {
            this.payoutsByUser = structuredClone(data.commission.payout.byUser);
            this.payoutsByUserChanged();
          }

          if (data.commission.aggregatedExecutiveRuleCommissions) {
            this.aggregatedExecutiveRuleCommissions =
              data.commission.aggregatedExecutiveRuleCommissions;

            for (const userId of data.commission
              .aggregatedExecutiveRuleCommissions.executiveLadder) {
              if (
                this.payoutsByUser[userId] === null ||
                this.payoutsByUser[userId] === undefined
              ) {
                this.payoutsByUser[userId] = 0;
              }
            }
          }
        }
        this.loading = false;
        this.cdr.detectChanges();
      })
      .catch(err => {
        console.error('createCustomPayouts err', err);
        this.loading = false;
        this.toastService.error(
          'Error creating custom payouts',
          'Please try again.'
        );
        this.cdr.detectChanges();
      });
  }
  deleteCustomPayouts() {
    if (!this.commission) {
      throw new Error('Commission is required');
    }
    this.loading = true;
    this.payoutCustomService
      .deleteCustomPayout(this.commission)
      .then(data => {
        console.log('createCustomPayouts res', data);

        if (data?.success === true) {
          this.payoutsByUser = {};

          delete this.aggregatedExecutiveRuleCommissions;
        }
        this.loading = false;
        this.cdr.detectChanges();
      })
      .catch(err => {
        console.error('createCustomPayouts err', err);
        this.loading = false;
        this.toastService.error(
          'Error creating custom payouts',
          'Please try again.'
        );
        this.cdr.detectChanges();
      });
  }

  saveCustomPayouts() {
    if (!this.commission) {
      throw new Error('Commission is required');
    }
    this.loadingSave = true;

    this.payoutCustomService
      .saveCustomPayout(this.commission, this.payoutsByUser)
      .then(data => {
        console.log('saveCustomPayouts res', data);
        this.loadingSave = false;
        this.toastService.success('Payouts saved');
        this.cdr.detectChanges();
      })
      .catch(err => {
        console.error('saveCustomPayouts err', err);
        this.loadingSave = false;
        this.toastService.error('Error saving payouts', 'Please try again.');
        this.cdr.detectChanges();
      });
  }

  payoutsByUserChanged() {
    this.totalCustomPayouts =
      Math.round(
        Object.values(this.payoutsByUser).reduce((a, b) => a + b, 0) * 100
      ) / 100;
    this.cdr.detectChanges();
  }

  payoutFocusOut(userId: string) {
    if (
      this.payoutsByUser[userId] === null ||
      this.payoutsByUser[userId] === undefined
    ) {
      this.payoutsByUser[userId] = 0;
    }
    this.payoutsByUserChanged();
  }
}
