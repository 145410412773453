import { Injectable } from '@angular/core';
import { ElasticService } from './elastic.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';
import { Commission } from '../models';
import {
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
} from '@angular/fire/firestore';
import {
  CommissionStatus,
  Commission_IdentifiedResult,
  commissionStatusToJSON,
} from '../models/classes/commissions';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root',
})
export class CommissionService {
  demoData: any;
  replacementOldCarrierName?: string;
  replacementNewCarrierName?: string;

  constructor(
    private cookieService: CookieService,
    private elasticService: ElasticService,
    private firestore: Firestore,
    private functions: Functions,
    private store: Store
  ) {
    if (environment.isDemo || !environment.production) {
      const item = this.cookieService.get('demo-pass');
      this.demoData = item ? JSON.parse(item) : null;
    }
  }

  load = (
    userId: string,
    filter: any,
    sizeCount = 20,
    fromDoc?: QueryDocumentSnapshot<DocumentData, DocumentData>
  ): Promise<any> =>
    new Promise(async resolve => {
      console.log('Filter', { userId, filter, fromDoc, sizeCount });

      let q = query(
        collection(this.firestore, 'agencies', userId, 'commissions')
      );

      if (filter.status) {
        q = query(
          q,
          where('status', '==', commissionStatusToJSON(filter.status))
        );
      }

      if (filter.term) {
        q = query(
          q,
          where('policyId', '>=', filter.term || ''),
          where('policyId', '<=', filter.term + '\uf8ff')
        );
      }

      q = query(q, orderBy('effectiveDate.timestamp', 'desc'));

      if (fromDoc) {
        const fromDocRef = await getDoc(
          doc(this.firestore, 'agencies', userId, 'commissions', fromDoc.id)
        );
        console.info('fromDoc', fromDoc.id);
        q = query(q, startAfter(fromDocRef));
      }

      if (sizeCount) {
        q = query(q, limit(sizeCount));
      }

      getDocs(q).then(res => {
        const commissions: Commission[] = [];

        console.log('docs', res.docs.length);
        for (const doc of res.docs) {
          commissions.push(
            Commission.fromJSON({
              ...doc.data(),
              id: doc.id,
            })
          );
        }

        let last = commissions[commissions.length - 1];

        resolve({ commissions, last });
      });
    });

  loadSingle = (
    userId: string,
    commissionId: string
  ): Promise<Commission | null> =>
    new Promise(async resolve => {
      console.log('loadSingle commission', { userId, commissionId });

      const docRes = await getDoc(
        doc(this.firestore, 'agencies', userId, 'commissions', commissionId)
      );

      if (!docRes.exists()) {
        return resolve(null);
      }

      resolve(
        Commission.fromJSON({
          ...docRes.data(),
          id: docRes.id,
        })
      );
    });

  retry(data: { agencyId: string; status: CommissionStatus }) {
    return httpsCallable(
      this.functions,
      'commissionsRetry'
    )({
      agencyId: data.agencyId,
      commissionStatus: commissionStatusToJSON(data.status),
    }).then(res => {
      console.log('retry started', res);

      return res;
    });
  }

  updateIdentifiedResult = (
    userId: string,
    commission: Commission,
    result: Commission_IdentifiedResult
  ) =>
    new Promise((resolve, reject) => {
      if (!commission.id) {
        return;
      }
      const docRef = doc(
        this.firestore,
        'agencies',
        userId,
        'commissions',
        commission.id
      );

      const toUpdate = {
        identifiedResult: Commission_IdentifiedResult.toJSON(result),
        status: commissionStatusToJSON(CommissionStatus.CS_PENDING),
      };

      setDoc(docRef, toUpdate, { merge: true })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
}
