<app-modal [wide]="true" #modal (dismissed)="modalDismissed()">
  @if (commission) {
    <h3>Commission</h3>

    <!-- <h4>Current Identified Result</h4>
    @if (commission.identifiedResult) {
      <div class="grid gap-2">
        <app-df-card [df]="commission.identifiedResult.df1"></app-df-card>

        @if (commission.identifiedResult.operationMessage) {
          <div>
            <p>
              Operation:
              <b>{{ commission.identifiedResult.operationMessage }}</b>
            </p>
          </div>
        }

        @if (commission.identifiedResult.df2) {
          <app-df-card [df]="commission.identifiedResult.df2"></app-df-card>
        }
      </div>
    } @else {
      <p>Not Identified</p>
    }

    @if (
      commission &&
      commission.possibleIdentifiedResult &&
      commission.possibleIdentifiedResult.length > 0
    ) {
      <h4>Possible Identified Results</h4>
      @for (result of commission.possibleIdentifiedResult; track result) {
        <div class="grid gap-2">
          <app-df-card [df]="result?.df1"></app-df-card>

          @if (result?.operationMessage) {
            <div>
              <p>
                Operation:
                <b>{{ result?.operationMessage }}</b>
              </p>
            </div>
          }

          @if (result?.df2) {
            <app-df-card [df]="result?.df2"></app-df-card>
          }
        </div>
      }
    } -->

    <div>
      <table class="table">
        <tbody>
          <tr>
            <td>id</td>
            <td>{{ commission.id }}</td>
          </tr>
          <tr>
            <td>Policy Id</td>
            <td>{{ commission.policyId }}</td>
          </tr>
          <tr>
            <td>Consumer</td>
            <td>
              {{ commission.consumer?.firstName }}
              {{ commission.consumer?.lastName }}
            </td>
          </tr>
          <tr>
            <td>agent</td>
            <td>
              <p>
                {{ commission.agent?.firstName }}
                {{ commission.agent?.lastName }}
              </p>
              <p>{{ commission.agent?.npn }}</p>
            </td>
          </tr>
          <tr>
            <td>Carrier</td>
            <td>
              @if (
                commission.carrier &&
                commission.carrier.id &&
                carrierMap[commission.carrier.id]
              ) {
                {{ carrierMap[commission.carrier.id].name }}
              } @else {
                Unknown
              }
            </td>
          </tr>
          <tr>
            <td>Type</td>
            <td>
              @if (commission.type) {
                {{
                  commissionTypeMap[commission.type]
                    ? commissionTypeMap[commission.type].title
                    : 'Unknown'
                }}
              } @else {
                Unset
              }
            </td>
          </tr>
          <!-- <tr>
            <td>Origin</td>
            <td>
              @if (commission.origin) {
                {{
                  commissionOriginMap[commission.origin]
                    ? commissionOriginMap[commission.origin].title
                    : 'Unknown'
                }}
              } @else {
                Unset
              }
            </td>
          </tr> -->
          <tr>
            <td>Effective Date</td>
            <td>{{ commission.effectiveDate?.timestamp | date }}</td>
          </tr>
          <tr>
            <td>Statement Date</td>
            <td>{{ commission.statementDate?.timestamp | date }}</td>
          </tr>
          <tr>
            <td>Termination Date</td>
            <td>{{ commission.terminationDate?.timestamp | date }}</td>
          </tr>
          <tr>
            <td>Payment Date</td>
            <td>{{ commission.paymentDate?.timestamp | date }}</td>
          </tr>
          <tr>
            <td>Signature Date</td>
            <td>{{ commission.signatureDate?.timestamp | date }}</td>
          </tr>
          <tr>
            <td>State</td>
            <td>{{ commission.state }}</td>
          </tr>
          <!-- <tr>
            <td>PlanType</td>
            <td>{{ commission.planType }}</td>
          </tr> -->
          <tr>
            <td>Plan Type</td>
            <td>
              @if (
                commission.planTypeCommission &&
                planTypeMap[commission.planTypeCommission]
              ) {
                {{ planTypeMap[commission.planTypeCommission] }}
              } @else {
                Unknown
              }
            </td>
          </tr>
          <tr>
            <td>Level</td>
            <td>{{ commission.level }}</td>
          </tr>
          <tr>
            <td>Commission Amount</td>
            <td>{{ commission.commissionAmount | currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <app-commissions-custom-payout-edit [commission]="commission">
    </app-commissions-custom-payout-edit>

    @if (
      !isDemo && (!production || authService.authUserType === UserType.AGENCY)
    ) {
      <div class="form-control">
        <label class="label cursor-pointer gap-2 justify-start">
          <input
            type="checkbox"
            class="checkbox"
            [ngModel]="showAdvanced"
            (ngModelChange)="toggleShowAdvanced()" />
          <span class="label-text">Show Advanced Data</span>
        </label>
      </div>
    }

    @if (
      commission &&
      ((commission.possibleIdentifiedResult &&
        commission.possibleIdentifiedResult.length > 0) ||
        commission.identifiedResult)
    ) {
      <table class="table mb-4">
        <thead>
          <tr>
            <th class="w-12"></th>
            <th>Plan Type</th>
            <th>Rate</th>
            <th>State</th>
            <th>Comment</th>
            <th>Level</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          @if (
            commission.possibleIdentifiedResult &&
            commission.possibleIdentifiedResult.length > 0
          ) {
            @for (result of commission.possibleIdentifiedResult; track result) {
              @if (result.df1 && result.df1.columnDetail) {
                <tr (click)="selectPossibleIdentifiedResult(result)">
                  <td>
                    @if (
                      newResult
                        ? compareResults(newResult, result)
                        : isChecked(commission, result)
                    ) {
                      <fa-icon [icon]="faCircleCheck"></fa-icon>
                    } @else {
                      <fa-icon [icon]="faCircle"></fa-icon>
                    }
                  </td>
                  <td>
                    @if (result.df1.columnDetail.planType) {
                      {{ planTypeMap[result.df1.columnDetail.planType].title }}
                    } @else {
                      Unknown
                    }
                  </td>

                  <td>
                    @if (result.df1.columnDetail.rate && commissionRateMap[result.df1.columnDetail.rate]) {
                      {{
                        commissionRateMap[result.df1.columnDetail.rate].title
                      }}
                    } @else {
                      Unknown
                    }
                  </td>

                  <td>
                    @if (
                      result.df1.columnDetail.state === 'other' ||
                      result.df1.columnDetail.state === 'Other'
                    ) {
                      National
                    } @else {
                      {{ result.df1.columnDetail.state }}
                    }
                  </td>

                  <td>
                    {{ result.df1.columnDetail.comment }}
                  </td>

                  <td>
                    {{ result.df1.columnDetail.level }}
                  </td>

                  <td>
                    {{ result.df1.columnDetail.value }}
                  </td>
                </tr>
              }
            }
            <!-- } @else {
            @if (
              commission.identifiedResult &&
              commission.identifiedResult.df1 &&
              commission.identifiedResult.df1.columnDetail
            ) {
              <tr>
                <td>
                  <fa-icon [icon]="faCircleCheck"></fa-icon>
                </td>
                <td>
                  @if (
                    commission.identifiedResult.df1.columnDetail.planType &&
                    planTypeMap[
                      commission.identifiedResult.df1.columnDetail.planType
                    ]
                  ) {
                    {{
                      planTypeMap[
                        commission.identifiedResult.df1.columnDetail.planType
                      ].title
                    }}
                  } @else {
                    Unknown
                  }
                </td>

                <td>
                  @if (commission.identifiedResult.df1.columnDetail.rate) {
                    @if (
                      commissionRateMap[
                        commission.identifiedResult.df1.columnDetail.rate
                      ]
                    ) {
                      {{
                        commissionRateMap[
                          commission.identifiedResult.df1.columnDetail.rate
                        ].title
                      }}
                    } @else {
                      {{ commission.identifiedResult.df1.columnDetail.rate }}
                    }
                  } @else {
                    Unknown
                  }
                </td>

                <td>
                  @if (
                    commission.identifiedResult.df1.columnDetail.state ===
                      'other' ||
                    commission.identifiedResult.df1.columnDetail.state ===
                      'Other'
                  ) {
                    National
                  } @else {
                    {{ commission.identifiedResult.df1.columnDetail.state }}
                  }
                </td>

                <td>
                  {{ commission.identifiedResult.df1.columnDetail.comment }}
                </td>

                <td>
                  {{ commission.identifiedResult.df1.columnDetail.level }}
                </td>

                <td>
                  {{ commission.identifiedResult.df1.columnDetail.value }}
                </td>
              </tr>
            } -->
          }
        </tbody>
      </table>

      @if (
        commission.possibleIdentifiedResult &&
        commission.possibleIdentifiedResult.length > 1 &&
        commission.identifiedResult &&
        newResult &&
        !compareResults(newResult, commission.identifiedResult)
      ) {
        @if (loading) {
          <button class="btn btn-primary">
            <app-loading></app-loading>Save Identified Result
          </button>
        } @else {
          <button class="btn btn-primary" (click)="saveIdentifiedResult()">
            <fa-icon [icon]="faSave"></fa-icon>Save Identified Result
          </button>
        }
      }
    } @else {
      <p>No possibility identified</p>
    }

    @if (showAdvanced) {
      <button
        class="btn btn-outline btn-sm"
        type="button"
        (click)="retry(commission)">
        Retry processing
      </button>

      <div class="mockup-code">
        <pre><code class="select-all">{{
          Commission.toJSON(commission) | json
        }}</code></pre>
      </div>
    }
  } @else {
    <app-loading></app-loading>
  }
</app-modal>
