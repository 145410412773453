import { Injectable } from '@angular/core';
import {
  Functions,
  HttpsCallableResult,
  httpsCallable,
} from '@angular/fire/functions';
import {
  Commission,
  CommissionStatus,
  DateContainer,
  commissionStatusToJSON,
} from '../../models';
import { Firestore, deleteField, doc, setDoc } from '@angular/fire/firestore';
import { dateToDateContainer } from '../../misc/dateToDateContainer';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class PayoutCustomService {
  constructor(
    private authService: AuthService,
    private firestore: Firestore,
    private functions: Functions
  ) {}

  async convertToCustomPayout(
    commission: Commission
  ): Promise<{ commission?: Commission }> {
    const convertResult = (await httpsCallable(
      this.functions,
      'commissionsConvertToCustomPayout'
    )({
      commissionId: commission.id,
      agencyId: commission.agency?.id,
    })) as HttpsCallableResult<{ commission: unknown }>;

    const data = convertResult?.data;

    const commissionParsed = data?.commission
      ? Commission.fromJSON(data.commission)
      : undefined;

    return { commission: commissionParsed };
  }

  async saveCustomPayout(
    commission: Commission,
    payoutsByUser: { [key: string]: number }
  ): Promise<{ success?: boolean }> {
    if (!commission.agency?.id || !commission.id) {
      throw new Error('Agency id and commission id are required');
    }

    const commissionRef = doc(
      this.firestore,
      'agencies',
      commission.agency.id,
      'commissions',
      commission.id
    );

    if (commission.aggregatedExecutiveRuleCommissions?.executiveLadder) {
      for (const userId of commission.aggregatedExecutiveRuleCommissions
        .executiveLadder) {
        if (
          payoutsByUser[userId] === null ||
          payoutsByUser[userId] === undefined
        ) {
          payoutsByUser[userId] = 0;
        }
      }
    }

    await setDoc(
      commissionRef,
      {
        payout: { byUser: payoutsByUser },
        custom: {
          isCustom: true,
          setByUserId: this.authService.user?.uid,
          updatedAt: DateContainer.toJSON(dateToDateContainer(new Date())),
        },
        status: commissionStatusToJSON(CommissionStatus.CS_PENDING),
      },
      { merge: true }
    );

    return { success: true };
  }

  async deleteCustomPayout(
    commission: Commission
  ): Promise<{ success?: boolean }> {
    if (!commission.agency?.id || !commission.id) {
      throw new Error('Agency id and commission id are required');
    }

    const commissionRef = doc(
      this.firestore,
      'agencies',
      commission.agency.id,
      'commissions',
      commission.id
    );

    await setDoc(
      commissionRef,
      {
        identifiedPayout: deleteField(),
        payout: deleteField(),
        custom: {
          isCustom: false,
          setByUserId: this.authService.user?.uid,
          updatedAt: DateContainer.toJSON(dateToDateContainer(new Date())),
        },
        status: commissionStatusToJSON(CommissionStatus.CS_PENDING),
      },
      { merge: true }
    );

    return { success: true };
  }
}
